import React from 'react'
import styled from 'styled-components'
import logo from 'images/footer/logo.svg'
import bg from 'images/footer/bg.svg'
import bgLg from 'images/footer/bg-l.svg'
import bgMd from 'images/footer/bg-md.svg'
import border from 'images/footer/border.svg'
import builtLogo from 'images/footer/built-logo.svg'
import { ReactComponent as TwitterIcon } from 'images/footer/twitter-icon.svg'
import { ReactComponent as MediumIcon } from 'images/footer/medium-icon.svg'
import { ReactComponent as TelegramIcon } from 'images/footer/telegram-icon.svg'
import { ReactComponent as DiscordIcon } from 'images/footer/discord-icon.svg'
import {
  APPLY_FOR_IDO_LINK,
  DECK_LINK,
  DISCORD,
  FAQ_LINK,
  MEDIUM,
  TELEGRAM,
  TWITTER,
} from 'configs/app'
import { Link } from 'react-scroll'

interface modalProps {
  onClick: () => void
}

const Footer: React.FunctionComponent<modalProps> = (props) => {
  return (
    <Root>
      <Container>
        <Line>
          <LogoBlock>
            <Logo />
            <LogoText>Algorithms of the Future</LogoText>
          </LogoBlock>
          <Column>
            <ColumnTitle>
              DAO
              <BorderWrap>
                <BorderImg />
                <Border />
              </BorderWrap>
            </ColumnTitle>
            <ColumnLink>
              <a href={FAQ_LINK} target={'_blank'} rel="noreferrer">
                About Us
              </a>
            </ColumnLink>
            {/*<Link>News</Link>*/}
            {/*<Link>Careers</Link>*/}
          </Column>
          <Column>
            <ColumnTitle>
              TECHNOLOGY
              <BorderWrap>
                <BorderImg />
                <Border />
              </BorderWrap>
            </ColumnTitle>
            <Row>
              <LinkColumn>
                {/*<Link>Whitepaper</Link>*/}
                <ColumnLink>
                  <a href={DECK_LINK} target={'_blank'} rel="noreferrer">
                    Deck
                  </a>
                </ColumnLink>
                <ColumnLink>
                  <a href={FAQ_LINK} target={'_blank'} rel="noreferrer">
                    FAQ
                  </a>
                </ColumnLink>
              </LinkColumn>
              <LinkColumn>
                {/*<Link>VC DAO</Link>*/}
                <ColumnLink>
                  <Link duration={300} smooth={true} to={'tiers'}>
                    Tiers
                  </Link>
                </ColumnLink>
                {/*<Link>DEX</Link>*/}
              </LinkColumn>
              <LinkColumn>
                <ColumnLink>
                  {' '}
                  <a
                    href={APPLY_FOR_IDO_LINK}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    Apply for IDO
                  </a>
                </ColumnLink>

                {/*<Link>Buy Sigma</Link>*/}
                {/*<Link>Ecosystem</Link>*/}
              </LinkColumn>
            </Row>
          </Column>
          <Column>
            <ColumnTitle>
              CONTACT{' '}
              <BorderWrap>
                <BorderImg />
                <Border />
              </BorderWrap>
            </ColumnTitle>
            <ColumnLink>
              <button onClick={props.onClick} type="button">
                Contact Us
              </button>
            </ColumnLink>

            {/*<Link>Forum</Link>*/}
          </Column>
          <Column>
            <ColumnTitle>
              FOLLOW US{' '}
              <BorderWrap>
                <BorderImg />
                <Border />
              </BorderWrap>
            </ColumnTitle>
            <SocialRow href={TELEGRAM} target={'_blank'}>
              <SocialIcon>
                <TelegramIcon />
              </SocialIcon>
              <SocialText>Telegram</SocialText>
            </SocialRow>
            <SocialRow href={MEDIUM} target={'_blank'}>
              <SocialIcon>
                <MediumIcon />
              </SocialIcon>
              <SocialText>Medium</SocialText>
            </SocialRow>
            <SocialRow href={TWITTER} target={'_blank'}>
              <SocialIcon>
                <TwitterIcon />
              </SocialIcon>
              <SocialText>Twitter</SocialText>
            </SocialRow>
            <SocialRow href={DISCORD} target={'_blank'}>
              <SocialIcon>
                <DiscordIcon />
              </SocialIcon>
              <SocialText>Discord</SocialText>
            </SocialRow>
          </Column>
        </Line>
        <CopyRightLine>
          <LeftBlock>
            <BuiltFor>
              <BuiltText>Built on and FOR</BuiltText>
              <BuiltLogo src={builtLogo} />
            </BuiltFor>
            <CopyRight>2024 ALGODAO, All rights reserved</CopyRight>
          </LeftBlock>
          {/*<Policy>Personal Data and Privacy Policies</Policy>*/}
        </CopyRightLine>
      </Container>
    </Root>
  )
}

export default Footer

const Root = styled.div`
  position: relative;
  padding-bottom: 29px;
  background: #fafafa;
  ${({ theme }) => theme.adaptive.index.xl} {
    padding-bottom: 25px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    padding-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    padding-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    padding-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    padding-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    padding-bottom: 26px;
  }
  &:before {
    ${({ theme }) => theme.adaptive.index.sm} {
      content: '';
      position: absolute;
      bottom: -22px;
      width: 100%;
      height: 381px;
      background: #fafafa;
    }
  }
`

const Container = styled.div`
  width: 1788px;
  margin: 0 auto;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 1540px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 1320px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 1137px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 843px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 329px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
  }
`

const Line = styled.div`
  position: relative;
  display: flex;
  ${({ theme }) => theme.adaptive.index.sm} {
    flex-wrap: wrap;
  }
  &:before {
    content: '';
    position: absolute;
    top: -895px;
    left: -1775px;
    width: 4698px;
    height: 1395px;
    background: url('${bg}') center no-repeat;
    background-size: cover;
    ${({ theme }) => theme.adaptive.index.xl} {
      top: -784px;
      left: -1554px;
      width: 4111px;
      height: 1194px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      top: -672px;
      left: -1332px;
      width: 3524px;
      height: 1254px;
      background: url('${bgLg}') center no-repeat;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      top: -579px;
      left: -1147px;
      width: 3034px;
      height: 1080px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      top: -425px;
      left: -851px;
      width: 2251px;
      height: 641px;
      background: url('${bgMd}') center no-repeat;
      background-size: cover;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      top: -375px;
      left: -764px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      width: 1921px;
      height: 547px;
      top: -320px;
      left: -647px;
    }
  }
`

const LogoBlock = styled.div`
  position: relative;
  margin-right: 153px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-right: 134px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 114px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 98px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-right: 61px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-right: 0;
    width: 100%;
    margin-bottom: 79px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-bottom: 83px;
  }
`

const Logo = styled.div`
  position: relative;
  width: 393px;
  height: 36px;
  margin-bottom: 22px;
  background: url('${logo}') center no-repeat;
  background-size: cover;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 344px;
    height: 32px;
    margin-bottom: 19px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 295px;
    height: 27px;
    margin-bottom: 17px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 254px;
    height: 23px;
    margin-bottom: 14px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 188px;
    height: 17px;
    margin-bottom: 11px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 240px;
    height: 22px;
    margin: 0 auto;
    margin-bottom: 15px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 205px;
    height: 19px;
    margin-bottom: 13px;
  }
`

const LogoText = styled.div`
  font-family: 'MMC';
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.01em;
  color: #de5253;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
    line-height: 14px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    text-align: center;
  }
`

const Column = styled.div`
  position: relative;
  margin-right: 148px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-right: 128px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 108px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 90px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-right: 49px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-right: 38px;
    order: 1;
    &:nth-child(3) {
      order: 4;
      margin-right: 0;
      margin-top: 60px;
    }
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-right: 30px;
    &:nth-child(3) {
      margin-top: 46px;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`

const ColumnTitle = styled.div`
  position: relative;
  font-family: 'Aldrich';
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #1d1e1f;
  margin-bottom: 46px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 23px;
    margin-bottom: 40px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 20px;
    margin-bottom: 34px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 17px;
    margin-bottom: 30px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 13px;
    margin-bottom: 23px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 16px;
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 14px;
    margin-bottom: 19px;
  }
`

const BorderWrap = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  left: 0;
  bottom: -5px;
  ${({ theme }) => theme.adaptive.index.xl} {
    bottom: -4px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    bottom: -4px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    bottom: -3px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    bottom: -3px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    bottom: -4px;
  }
`

const BorderImg = styled.div`
  width: 59px;
  height: 2px;

  background: url('${border}') center no-repeat;
  margin-right: 3px;
  ${({ theme }) => theme.adaptive.index.xl} {
    background-size: cover;
    width: 52px;
    height: 2px;
    margin-right: 3px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 44px;
    height: 2px;
    margin-right: 3px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 38px;
    height: 2px;
    margin-right: 2px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 28px;
    height: 2px;
    margin-right: 2px;
  }
`

const Border = styled.div`
  width: 100%;
  border: 1px dashed #d45151;
`

const Row = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 315px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 270px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 245px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 238px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 292px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 256px;
  }
`

const LinkColumn = styled.div`
  max-width: 100px;
  ${({ theme }) => theme.adaptive.index.xl} {
    max-width: 86px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    max-width: 74px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    max-width: 75px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    max-width: 85px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    max-width: 80px;
  }
`

const ColumnLink = styled.div`
  margin-bottom: 25px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-bottom: 18px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  a {
    font-family: 'MMC';
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 23px;
    color: #272a32;
    transition: 0.4s;
    cursor: pointer;
    ${({ theme }) => theme.adaptive.index.xl} {
      font-size: 14px;
      line-height: 20px;
    }
    ${({ theme }) => theme.adaptive.index.lg} {
      font-size: 12px;
      line-height: 17px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      font-size: 12px;
      line-height: 15px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      font-size: 12px;
      line-height: 11px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      font-size: 14px;
      line-height: 11px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      font-size: 13px;
      line-height: 9px;
    }
    &:hover {
      color: #d55054;
    }
  }
`

const CopyRightLine = styled.div`
  position: relative;
  margin-top: 176px;
  display: flex;
  justify-content: space-between;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-top: 153px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-top: 131px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-top: 113px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-top: 84px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 329px;
    margin: 0 auto;
    margin-top: 86px;
    display: block;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    width: 280px;
    margin-top: 78px;
  }
`

const LeftBlock = styled.div`
  display: flex;
  ${({ theme }) => theme.adaptive.index.sm} {
    display: block;
  }
`

const BuiltFor = styled.div`
  display: flex;
  align-items: center;
  margin-right: 332px;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-right: 290px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-right: 249px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-right: 214px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-right: 159px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 100%;
    margin-right: 0;
  }
`

const BuiltText = styled.div`
  font-family: 'Aldrich';
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: #272a32;
  margin-right: 7px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 12px;
    line-height: 12px;
    margin-right: 6px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 10px;
    line-height: 10px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 9px;
    line-height: 9px;
    margin-right: 5px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 7px;
    line-height: 7px;
    margin-right: 3px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 12px;
    line-height: 12px;
    margin-right: 3px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-right: 4px;
  }
`

const BuiltLogo = styled.img`
  display: block;
  width: 77px;
  height: 21px;
  ${({ theme }) => theme.adaptive.index.xl} {
    width: 67px;
    height: 18px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    width: 58px;
    height: 16px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    width: 50px;
    height: 14px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    width: 37px;
    height: 10px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    width: 57px;
    height: 15px;
  }
`

const CopyRight = styled.div`
  font-family: 'MMC';
  font-size: 16px;
  line-height: 23px;
  color: #272a32;
  opacity: 0.3;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    line-height: 20px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
    line-height: 17px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 10px;
    line-height: 15px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 8px;
    line-height: 11px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 12px;
    line-height: 11px;
    margin-top: 20px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 12px;
    line-height: 9px;
    margin-top: 16px;
  }
`

// const Policy = styled.a`
//   display: block;
//   font-family: 'MMC';
//   font-size: 16px;
//   line-height: 23px;
//   color: #272a32;
//   opacity: 0.3;
//   outline: none;
//   ${({ theme }) => theme.adaptive.index.xl} {
//     font-size: 14px;
//     line-height: 20px;
//   }
//   ${({ theme }) => theme.adaptive.index.lg} {
//     font-size: 12px;
//     line-height: 17px;
//   }
//   ${({ theme }) => theme.adaptive.index.slg} {
//     font-size: 10px;
//     line-height: 15px;
//   }
//   ${({ theme }) => theme.adaptive.index.md} {
//     font-size: 8px;
//     line-height: 11px;
//   }
//   ${({ theme }) => theme.adaptive.index.sm} {
//     font-size: 12px;
//     line-height: 11px;
//     margin-top: 20px;
//   }
//   ${({ theme }) => theme.adaptive.index.xs} {
//     font-size: 12px;
//     line-height: 9px;
//     margin-top: 16px;
//   }
// `

const SocialIcon = styled.div`
  svg {
    display: block;
    max-width: 19px;
    max-height: 14px;
    fill: #272a32;
    transition: 0.4s;
    ${({ theme }) => theme.adaptive.index.lg} {
      max-width: 15px;
      max-height: 11px;
    }
    ${({ theme }) => theme.adaptive.index.slg} {
      max-width: 12px;
      max-height: 9px;
    }
    ${({ theme }) => theme.adaptive.index.md} {
      max-width: 12px;
      max-height: 9px;
    }
    ${({ theme }) => theme.adaptive.index.sm} {
      max-width: 12px;
      max-height: 9px;
    }
    ${({ theme }) => theme.adaptive.index.xs} {
      max-width: 11px;
      max-height: 9px;
    }
  }
`

const SocialText = styled.div`
  font-family: 'MMC';
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 23px;
  color: #272a32;
  transition: 0.4s;
  margin-left: 15px;
  ${({ theme }) => theme.adaptive.index.xl} {
    font-size: 14px;
    line-height: 20px;
    margin-left: 13px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    font-size: 12px;
    line-height: 17px;
    margin-left: 11px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    font-size: 12px;
    line-height: 15px;
    margin-left: 10px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    font-size: 12px;
    line-height: 11px;
    margin-left: 8px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    font-size: 14px;
    line-height: 11px;
    margin-left: 9px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    font-size: 13px;
    line-height: 9px;
    margin-left: 8px;
  }
`

const SocialRow = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  ${({ theme }) => theme.adaptive.index.xl} {
    margin-bottom: 21px;
  }
  ${({ theme }) => theme.adaptive.index.lg} {
    margin-bottom: 18px;
  }
  ${({ theme }) => theme.adaptive.index.slg} {
    margin-bottom: 16px;
  }
  ${({ theme }) => theme.adaptive.index.md} {
    margin-bottom: 12px;
  }
  ${({ theme }) => theme.adaptive.index.sm} {
    margin-bottom: 22px;
  }
  ${({ theme }) => theme.adaptive.index.xs} {
    margin-bottom: 18px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    ${SocialIcon} {
      svg {
        fill: #d55054;
      }
    }
    ${SocialText} {
      color: #d55054;
    }
  }
`
